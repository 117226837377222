// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Porson.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("BreitkopfFraktur.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("SweynheimPannartz.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Ubuntu-R.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Copyright 2025 Paion Data. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
*/
@font-face {
  font-family: Porson;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: BreitkopfFraktur;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-family: SweynheimPannartz;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: Ubuntu;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.flashcard-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  vertical-align: middle;
  font-size: 30px;
}
.greek-side {
  font-family: Porson;
}
.german-side {
  font-family: BreitkopfFraktur;
}
.latin-side {
  font-family: SweynheimPannartz;
}
.english-side {
  font-family: Ubuntu;
}
`, "",{"version":3,"sources":["webpack://./packages/wilhelm-app/src/components/flashcard/FlashCard.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;CAcC;AACD;EACE,mBAAmB;EACnB,4CAAoB;AACtB;;AAEA;EACE,6BAA6B;EAC7B,4CAA8B;AAChC;;AAEA;EACE,8BAA8B;EAC9B,4CAA+B;AACjC;;AAEA;EACE,mBAAmB;EACnB,4CAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,mBAAmB;AACrB","sourcesContent":["/**\n * Copyright 2025 Paion Data. All rights reserved.\n *\n * Licensed under the Apache License, Version 2.0 (the \"License\");\n * you may not use this file except in compliance with the License.\n * You may obtain a copy of the License at\n *\n *     http://www.apache.org/licenses/LICENSE-2.0\n *\n * Unless required by applicable law or agreed to in writing, software\n * distributed under the License is distributed on an \"AS IS\" BASIS,\n * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.\n * See the License for the specific language governing permissions and\n * limitations under the License.\n*/\n@font-face {\n  font-family: Porson;\n  src: url(Porson.ttf);\n}\n\n@font-face {\n  font-family: BreitkopfFraktur;\n  src: url(BreitkopfFraktur.ttf);\n}\n\n@font-face {\n  font-family: SweynheimPannartz;\n  src: url(SweynheimPannartz.ttf);\n}\n\n@font-face {\n  font-family: Ubuntu;\n  src: url(Ubuntu-R.ttf);\n}\n\n.flashcard-text {\n  position: relative;\n  top: 50%;\n  transform: translateY(-50%);\n  text-align: center;\n  vertical-align: middle;\n  font-size: 30px;\n}\n.greek-side {\n  font-family: Porson;\n}\n.german-side {\n  font-family: BreitkopfFraktur;\n}\n.latin-side {\n  font-family: SweynheimPannartz;\n}\n.english-side {\n  font-family: Ubuntu;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
